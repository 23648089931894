import React from 'react';

const planeIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.0883 20.7032L17.5005 9.11671V3.97842C17.5005 3.16725 17.3205 2.34982 16.9655 1.58735L16.7018 1.08741C16.3906 0.416215 15.7394 0 15.0007 0C14.262 0 13.6108 0.416215 13.3133 1.05864L13.0221 1.61483C12.6809 2.34976 12.5009 3.16596 12.5009 3.97836V9.11666L0.916876 20.7008C0.326945 21.2907 0.00195312 22.0744 0.00195312 22.9093V24.3742C0.00195312 24.603 0.126923 24.813 0.326945 24.9217C0.526909 25.0317 0.771928 25.0242 0.964392 24.8992L12.7434 17.3511C12.8596 19.2422 13.0171 20.8208 13.2096 22.7419L13.4083 24.7105L9.03243 27.6015C8.85619 27.719 8.7512 27.9139 8.7512 28.1239V29.3738C8.7512 29.5625 8.83744 29.7425 8.98491 29.8613C9.13367 29.9812 9.32736 30.0262 9.5111 29.985L15.0006 28.7638L20.4902 29.985C20.5352 29.995 20.5802 30 20.6252 30C20.7664 30 20.9039 29.9525 21.0164 29.8625C21.1638 29.7425 21.2501 29.5625 21.2501 29.3738V28.1239C21.2501 27.9139 21.1451 27.7177 20.9688 27.6027L16.593 24.7155L16.7917 22.7406C16.9842 20.822 17.1416 19.2447 17.2579 17.3535L29.0369 24.8991C29.2306 25.0241 29.4744 25.0304 29.6731 24.9216C29.8743 24.8129 29.9993 24.6029 29.9993 24.3742V22.9093C29.9994 22.0744 29.6757 21.2907 29.0883 20.7032Z"
      fill="#039BE5"
    />
  </svg>
);

export default planeIcon;
