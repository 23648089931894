const firebaseConfig = {
  apiKey: 'AIzaSyB-LVfcW9NoI4f3Yl9sMp7ZuMyuRmerBKc',
  authDomain: 'web2-fa88d.firebaseapp.com',
  databaseURL: 'https://web2-fa88d.firebaseio.com',
  projectId: 'web2-fa88d',
  storageBucket: 'web2-fa88d.appspot.com',
  messagingSenderId: '393511424397',
  appId: '1:393511424397:web:e4605e846e312e9ef16dc4',
  measurementId: 'G-920T7Z7L8C',
};

export default firebaseConfig;
